import { allErrorMessages, regex, commonPasswords } from "../data";
import purify from "../dom-purify";
import isEmailValid from "../email-validator";
let errorMsg = {};
let errorStatus = {};
const validateFirstName = (value) => {
  if (value === "") {
    errorMsg.fname = allErrorMessages.fname.isEmpty;
    return true;
  }
  if (value !== purify(value)) {
    errorMsg.fname = allErrorMessages.fname.isClean;
    return true;
  }
  return false;
};
const validateLastName = (value) => {
  if (value === "") {
    errorMsg.lname = allErrorMessages.lname.isEmpty;
    return true;
  }
  if (value !== purify(value)) {
    errorMsg.lname = allErrorMessages.lname.isClean;
    return true;
  }
  return false;
};

const checkSpecialCharacters = (value, validate) => {
  const specialCharsRegex =
    validate === "email"
      ? /[!#'$%^&*()\=\[\]{};:"\\|,`<>\/?]/
      : /[!@#$%^&*()_+\=\[\]{};:"\\|,`<>\/?]/;

  return value.split("").filter((char) => char.match(specialCharsRegex));
};

const specialCharsVerification = (value, validate, getErrorMessage) => {
  const hasSpecialChar = checkSpecialCharacters(value, validate);
  if (hasSpecialChar.length) {
    const errorMessage =
      hasSpecialChar.length === 1
        ? `${getErrorMessage}: ${hasSpecialChar[0]}`
        : `${getErrorMessage}: ${[...new Set(hasSpecialChar)].join(" ")}`;
    errorMsg[validate] = errorMessage;
    return !!errorMessage;
  }
};

const validateName = (value) => {
  const nameErrorMessage = allErrorMessages.name.isClean;
  const hasSpecialChar = specialCharsVerification(
    value,
    "name",
    nameErrorMessage
  );
  if (hasSpecialChar) {
    return true;
  }
  if (value === "") {
    errorMsg.name = allErrorMessages.name.isEmpty;
    return true;
  }
  if (value.length > 124) {
    errorMsg.name = allErrorMessages.name.isLong;
    return true;
  }
  if (value !== purify(value)) {
    errorMsg.name = allErrorMessages.name.isClean;
    return true;
  }
  if (checkForbiddenWords(value)) {
    errorMsg.name = allErrorMessages.name.isForbidden;
    errorStatus.name = false;
    return true;
  }
  return false;
};

const validateEmail = (value) => {
  const emailErrorMessage = allErrorMessages.email.isClean;
  const hasSpecialChar = specialCharsVerification(
    value,
    "email",
    emailErrorMessage
  );

  if (hasSpecialChar) {
    return true;
  }

  if (value === "") {
    errorMsg.email = allErrorMessages.email.isEmpty;
    return true;
  }
  if (value !== purify(value)) {
    errorMsg.email = allErrorMessages.email.isClean;
    return true;
  }
  if (!isEmailValid(value)) {
    errorMsg.email = allErrorMessages.email.isInValid;
    return true;
  }
  if (value.length > 64) {
    errorMsg.email = allErrorMessages.email.isLong;
    return true;
  }
  if (checkForbiddenWords(value)) {
    errorMsg.email = allErrorMessages.email.isForbidden;
    return true;
  }
  return false;
};

const validatePassword = (value) => {
  if (value === "") {
    errorMsg.password = allErrorMessages.password.isEmpty;
    return true;
  }
  if (value !== purify(value)) {
    errorMsg.password = allErrorMessages.password.isClean;
    return true;
  }
  if (value !== value.trim()) {
    errorMsg.password = allErrorMessages.password.isInValid;
    return true;
  }
  if (value.length < 8) {
    errorMsg.password = allErrorMessages.password.isShort;
    return true;
  }
  if (value.length > 128) {
    errorMsg.password = allErrorMessages.password.isLong;
    return true;
  }
  if (!regex.hasLettersandNumbers.test(value)) {
    errorMsg.password = allErrorMessages.password.isLettersandNumbers;
    return true;
  }
  if (commonPasswords.includes(value)) {
    errorMsg.password = allErrorMessages.password.isCommonPassword;
    return true;
  }
  return false;
};

const validateCompanySizeDropdown = (form) => {
  let value = form.querySelector("#form-dropdown-value").dataset.value;
  if (value === "undefined") {
    errorMsg.companySizeDropdown = allErrorMessages.dropdown.isEmpty;
    return true;
  }
  return false;
};

function isFormValid(err) {
  // Error Object should not have any errors (i.e., true boolean)
  let allErrorFlag = Object.values(err);
  return allErrorFlag.every((val) => val === false);
}

function checkForbiddenWords(input) {
  const forbiddenPhrases = [
    "call girls",
    "call girl",
    "girls call",
    "girl call",
    "escorts call",
    "call escorts",
    "escorts",
    "escort",
    "girls escorts",
    "escorts girls",
    "call escort",
    "escort call",
    "callgirls",
    "callgirl",
    "callescorts",
    "callescort",
    "escortcall",
  ];

  const lowerCaseInput = input.toLowerCase();

  // Check the input against the forbidden phrases
  for (let phrase of forbiddenPhrases) {
    if (lowerCaseInput.includes(phrase)) {
      return true;
    }
  }

  return false;
}

export default function validate(data, form) {
  errorMsg = {};
  errorStatus = {};
  if (data.hasOwnProperty("fname")) {
    errorStatus.fname = validateFirstName(form["first-name"].value);
  }
  if (data.hasOwnProperty("lname")) {
    errorStatus.lname = validateLastName(form["last-name"].value);
  }
  if (data.hasOwnProperty("name")) {
    errorStatus.name = validateName(form.name.value);
  }
  if (data.hasOwnProperty("email")) {
    errorStatus.email = validateEmail(form.email.value);
  }
  if (data.hasOwnProperty("password")) {
    errorStatus.password = validatePassword(form.password.value);
  }
  if (data.hasOwnProperty("dropdowns") && data.dropdowns.companySize) {
    errorStatus.companySizeDropdown = validateCompanySizeDropdown(form);
  }
  isFormValid(errorStatus);

  return {
    errors: {
      message: errorMsg,
      status: errorStatus,
    },
    isFormValid: isFormValid(errorStatus),
  };
}
