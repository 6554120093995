import { viewport, colors, font, lineHeight } from "../helpers/variables";

export const StyledForm = ({ children }) => {
  return (
    <>
      {children}
      <style jsx>{`
        :global(form) {
          position: relative;
          max-width: 460px;
          width: 100%;
          min-width: 400px;
          padding: 48px 80px;
          box-shadow: 0 14px 32px 0 rgb(22 45 61 / 8%),
            0 1px 4px 0 rgb(22 45 61 / 10%);
          border-radius: 8px;
          background-color: ${colors.primitive.white.default};
        }
        @media (max-width: ${viewport.tablet.md}) {
          :global(form) {
            min-width: 250px;
            padding: 20px;
          }
        }
      `}</style>
    </>
  );
};
export const StyledHeading = ({ children }) => {
  return (
    <h3>
      {children}
      <style jsx>{`
        h3 {
          font-size: ${font.size.md[2]};
          line-height: ${lineHeight[7]};
          margin-bottom: 16px;
        }
      `}</style>
    </h3>
  );
};
export const StyledInput = ({ children }) => {
  return (
    <div>
      {children}
      <style jsx>{`
        div {
          position: relative;
          margin-bottom: 8px;
        }
        div :global(input) {
          outline: 0;
          width: 100%;
          padding: 32px 0 16px;
          font-size: ${font.size.sm[4]};
          color: ${colors.derived.primary};
          border-bottom: 1px solid ${colors.primitive.black[300]};
          border-radius: 0;
        }
        div :global(input::placeholder) {
          color: ${colors.primitive.black[400]};
        }
        div :global(input:focus:not(.error)) {
          border-bottom-color: ${colors.derived.brand};
          font-size: ${font.size.sm[4]};
        }
        div :global(.error) {
          border-bottom-color: ${colors.derived.error};
        }
        :global(.view-password) {
          background: transparent;
          border: none;
          padding: 0;
          margin: 0;
          cursor: pointer;

          position: absolute;
          right: 16px;
          top: 50%;
          stroke: ${colors.primitive.grey[1600]};
        }
        :global(.view-password svg) {
          transition: stroke 0.2s ease-in-out;
        }
        :global(.view-password:hover svg) {
          stroke: ${colors.primitive.black[1200]};
        }
      `}</style>
    </div>
  );
};
export const StyledError = ({ children, id }) => {
  return (
    <div aria-live="polite" id={id}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 21 20"
      >
        <path
          fill="#DD2727"
          fill-rule="evenodd"
          d="M10.527 1.667c4.602 0 8.333 3.73 8.333 8.333 0 4.602-3.731 8.333-8.333 8.333-4.603 0-8.334-3.73-8.334-8.333 0-4.602 3.731-8.333 8.334-8.333zm0 1.158C6.564 2.825 3.35 6.037 3.35 10c0 3.963 3.213 7.175 7.176 7.175s7.175-3.212 7.175-7.175c0-3.963-3.212-7.175-7.175-7.175zm0 9.698l.09.005c.42.045.747.4.747.832 0 .463-.375.838-.837.838-.463 0-.838-.375-.838-.838 0-.431.327-.787.746-.832l.092-.005zm0-7.755c.293 0 .535.218.573.5l.006.08v5.686c0 .32-.26.579-.58.579-.292 0-.535-.218-.573-.5l-.005-.08V5.348c0-.32.259-.579.579-.579z"
        />
      </svg>
      <span>{children}</span>
      <style jsx>{`
        div {
          position: absolute;
          left: 0;
          bottom: -20px;
          z-index: 1;
          font-size: ${font.size.sm[1]};
          pointer-events: none;
        }
        svg {
          margin-right: 4px;
          line-height: 0;
        }
        svg,
        span {
          vertical-align: middle;
        }
      `}</style>
    </div>
  );
};
export const StyledSubmit = ({ children }) => {
  return (
    <div>
      {children}
      <style jsx>{`
        :global(.jsx-btn-primary) {
          display: block;
          margin-top: 32px;
          width: 100%;
          height: 46px;
          font-size: ${font.size.sm[4]};
          line-height: ${lineHeight[10]};
          color: ${colors.primitive.white.default};
          background-color: ${colors.primitive.green[800]};
          border: 0;
          border-radius: 6px;
          cursor: pointer;
          transition: 0.3s background-color linear;
          text-align: center;
          font-family: inherit;
        }
        :global(.jsx-btn-primary):hover {
          background-color: ${colors.primitive.green[900]};
        }
        :global(.jsx-btn-primary.disabled) {
          opacity: 0.3;
          pointer-events: none;
          cursor: default;
        }
      `}</style>
    </div>
  );
};
export const StyledToast = ({ children, isToastVisible }) => {
  return (
    <div
      className={`jsx-toast ${isToastVisible && `is-visible`}`}
      role="alert"
      aria-live="assertive"
    >
      {children}
      <style jsx>{`
        div {
          position: absolute;
          left: 50%;
          top: -18px;
          transform: translateX(-50%);
          font-size: ${font.size.sm[2]};
          width: 100%;
          max-width: 300px;
          color: ${colors.primitive.white.default};
          padding: 12px;
          background-color: ${colors.primitive.shadow.deep};
          border-radius: 30px;
          text-align: center;
          opacity: 0;
          transition: 0.3s opacity linear;
          opacity: 1 !important;
          z-index: 100;
        }
        :global(.is-visible) {
          opacity: 1 !important;
        }
        @media (max-width: ${viewport.tablet.sm}) {
          div {
            position: fixed;
            top: 45px;
          }
        }
      `}</style>
    </div>
  );
};

export const StyledDropdown = ({ children }) => {
  return (
    <div className="jsx-dropdown">
      {children}
      <style jsx>{`
        .jsx-dropdown {
          position: relative;
          margin-bottom: 40px;
        }
        .jsx-dropdown :global(.jsx-dropdown-current) {
          position: relative;
          width: 100%;
          border-bottom: 1px solid ${colors.primitive.grey[1100]};
          color: ${colors.primitive.black[400]};
          font-size: ${font.size.sm[4]};
          padding: 32px 0 10px;
          cursor: pointer;
        }
        div :global(.selected) :global(.jsx-dropdown-current) {
          font-size: ${font.size.sm[4]};
          color: ${colors.derived.primary};
        }
        :global(.jsx-dropdown-menu) {
          display: none;
          padding: 8px 0;
          width: 100%;
          position: absolute;
          z-index: 1;
          background: ${colors.primitive.white.default};
          box-shadow: 0 3px 4px 0 rgb(10 31 68 / 10%),
            0 0 1px 0 rgb(10 31 68 / 8%);
          border-radius: 4px;
        }
        :global(.jsx-dropdown-menu) :global(ul) {
          max-height: 200px;
          overflow: auto;
        }
        :global(.jsx-dropdown-menu) :global(li) {
          display: flex;
          padding: 16px 16px 16px 24px;
          cursor: pointer;
          font-size: ${font.size.sm[3]};
          color: ${colors.primitive.black[400]};
        }
        :global(.jsx-dropdown-menu) :global(li:hover) {
          background-color: ${colors.primitive.grey[1200]};
        }
        :global(.jsx-drop-arrow) {
          width: 10px;
          height: 10px;
          border: 1px solid ${colors.primitive.black[100]};
          border-width: 0 0 1px 1px;
          margin-left: auto;
          transition: all 0.3s ease;
          position: absolute;
          right: 14px;
          transform: rotate(-45deg);
          bottom: 14px;
          transform-origin: center;
        }
        // states
        div :global(.open) :global(.jsx-dropdown-current) {
          border-bottom-color ${colors.derived.brand};
        }
        div :global(.open) :global(.jsx-dropdown-menu) {
          display: block;
        }
        div :global(.open) :global(.jsx-drop-arrow) {
          transform: rotate(135deg);
          bottom: 10px;
        }
        div :global(.error) :global(.jsx-dropdown-current) {
          border-bottom-color: ${colors.derived.error};

        }
      `}</style>
    </div>
  );
};

export const StyledGoogleButton = ({
  children,
  action,
  onClick,
  id,
  onFocus,
}) => {
  return (
    <div className="auth-btns">
      <a href={action} id={id} onClick={onClick} onFocus={onFocus}>
        <img
          src={`https://assets.setmore.com/website/v2/images/icons/icon-google.svg`}
          alt=""
        />
        <span>{children}</span>
      </a>
      <style jsx>{`
        div {
          text-align: center;
          display: inline-block;
          min-width: 140px;
        }
        @media (max-width: ${viewport.mobile.sm}) {
          div {
            min-width: 100px;
          }
        }
        a {
          border: 1px solid ${colors.primitive.black[300]};
          border-radius: 6px;
          background-color: ${colors.primitive.white.default};
          display: inline-block;
          line-height: ${font.size.lg[3]};
          width: 100%;
          transition: 0.4s border-color, 0.4s box-shadow;
        }
        a:hover {
          border-color: ${colors.primitive.black[400]};
          box-shadow: 0 2px 7px -1px ${colors.primitive.black[300]};
        }
        a > img,
        span {
          vertical-align: middle;
        }
        a > img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      `}</style>
    </div>
  );
};
export const StyledFbButton = ({ children, action, id, onClick, onFocus }) => {
  return (
    <div className="auth-btns">
      <a href={action} id={id} onClick={onClick} onFocus={onFocus}>
        <img
          src={`https://assets.setmore.com/website/v2/images/icons/icon-facebook-blue.svg`}
          alt=""
        />
        <span>{children}</span>
      </a>
      <style jsx>{`
        div {
          text-align: center;
          display: inline-block;
          min-width: 140px;
        }
        @media (max-width: ${viewport.mobile.sm}) {
          div {
            min-width: 100px;
          }
        }
        a {
          border: 1px solid ${colors.primitive.black[300]};
          border-radius: 6px;
          background-color: ${colors.primitive.white.default};
          display: inline-block;
          line-height: ${font.size.lg[3]};
          width: 100%;
          transition: 0.4s border-color, 0.4s box-shadow;
        }
        a:hover {
          border-color: ${colors.primitive.black[400]};
          box-shadow: 0 2px 7px -1px ${colors.primitive.grey[1300]};
        }
        a > img,
        span {
          vertical-align: middle;
        }
        a > img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      `}</style>
    </div>
  );
};

export const StyledSocialButtonInline = ({ children }) => {
  return (
    <div className="btns-row">
      {children}
      <style jsx>{`
        .btns-row {
          display: flex;
          justify-content: space-between;
          margin-top: 40px;
          gap: 24px;
        }
        .btns-row :global(.auth-btns) :global(a) {
          line-height: ${lineHeight[10]};
        }
        @media (max-width: ${viewport.tablet.md}) {
          .btns-row :global(.auth-btns) {
            width: 50%;
          }
          .btns-row {
            gap: 6px;
          }
        }
      `}</style>
    </div>
  );
};

export const StyledSocialButtonStacked = ({ children }) => {
  return (
    <div className="btns-stack">
      {children}
      <style jsx>{`
        .btns-stack {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        .btns-stack :global(.auth-btns) {
          width: 100%;
          display: block;
        }
      `}</style>
    </div>
  );
};

export const StyledFormSeperator = () => {
  return (
    <div>
      or
      <style jsx>{`
        div {
          display: flex;
          text-align: center;
          align-items: center;
          margin-top: 24px;
        }
        div:after,
        div:before {
          flex: 1;
          content: "";
          border-bottom: 1px solid ${colors.primitive.black[300]};
        }
        div:after {
          margin-left: 13px;
        }
        div:before {
          margin-right: 13px;
        }
      `}</style>
    </div>
  );
};
