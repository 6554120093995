export const allErrorMessages = {
  name: {
    isEmpty: "Name required",
    isClean: "Name must not contain",
    isForbidden: "Uh-oh, this name doesn’t comply with our Terms of Use",
    isLong: "Enter up to 124 characters",
  },
  fname: {
    isEmpty: "First name field cannot be blank...",
    isClean: "First name must not contain '<'",
    isInvalid: "Invalid Firstname",
  },
  lname: {
    isEmpty: "Last name field cannot be blank...",
    isClean: "Last name must not contain '<'",
    isInvalid: "Invalid Lastname",
  },
  email: {
    isEmpty: "Email address required",
    isClean: "Email must not contain",
    isInValid: "Invalid Email",
    isSame: "Email address already exists!",
    isForbidden: "Uh-oh, this email doesn’t comply with our Terms of Use",
    isLong: "Enter up to 64 characters",
    error: "Error sending your details — please try again.",
  },
  phone: {
    isEmpty: "Phone number field cannot be blank...",
    isShort: "Phone number should have minimum of 5 characters",
    isLettersandNumbers: "Phone number must contain only numbers",
    isInValid: "Invalid Phone Number",
    isClean: "Phone number must not contain '<'",
  },
  password: {
    isEmpty: "Password field cannot be blank...",
    isClean: "Password must not contain '<'",
    isInValid: "Invalid Password",
    isShort: "Password should have minimum of 8 characters",
    isLong: "Password should not exceed 128 characters!",
    isLettersandNumbers: "Password must contain letters and numbers",
    isCommonPassword: "Most common passwords are not allowed",
  },
  dropdown: {
    isEmpty: "Company field cannot be blank. Please select one...",
  },
};

export const regex = {
  hasLettersandNumbers:
    /^(?=.*\d)[0-9a-zA-Z!"#$%&'()*+,\\./:;<=>?@[\]\^_`{|}~\-\s]{8,}$/, //escaped ],-,^,\
};

export const commonPasswords = [
  "password123",
  "setmore@123",
  "setmore123",
  "google123",
  "interview123",
];

export const commonErrorMessages = [
  "Account already exist!. Please proceed with login",
];

export const allToastMessages = {
  signup: {
    googleAuth: "Signing up with Google. Please wait...",
    facebookAuth: "Signing up with Facebook. Please wait...",
    availability: "Checking Availability. Please wait...",
    success: "Signing up, please wait...",
    emailAlreadyExists: "Email already exists",
  },
  affiliates: {
    availability: "Checking Availability. Please wait...",
    confirmation: "Signing up. At the next stage, please sign in.",
  },
  whitepaper: {
    sucess: "Enjoy your copy!",
  },
  enterprise: {
    availability: "Sending your request. Please wait...",
    success:
      "Thanks for sharing your details. One of our Setmore experts will be in touch shortly.",
    error: "Error sending your details — please try again.",
  },
};
